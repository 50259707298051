import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Layout, Header, PageTitle, PageSubTitle, Main, Footer, mq } from '../components/Layout';
import { Container } from '../components/Container';
import { Section, Title } from '../components/Section';
import { useEmailObfuscator } from '../hooks/useEmailObfuscator';

const ContactPage = () => {
  const obfuscator = useEmailObfuscator('bestuur@stichtingmijngilde.nl');

  return (
    <>
      <Helmet>
        <title>Contact</title>
      </Helmet>

      <Layout>
        <Header />

        <Main>
          <Section css={{ paddingBottom: '24px' }}>
            <Container
              css={{
                padding: '12px',
              }}
            >
              <div css={{ fontSize: '6px', [mq(1)]: { maxWidth: '50%' } }}>
                <PageTitle>Contact</PageTitle>
                <PageSubTitle>Op ons kun je rekenen!</PageSubTitle>
              </div>
            </Container>
          </Section>

          <Container>
            <div
              css={{
                padding: '6px',
              }}
            >
              <Section
                css={{ paddingLeft: '6px', paddingRight: '6px', [mq(1)]: { maxWidth: '75%' } }}
              >
                <Title>Adresgegevens</Title>
                <main>
                  <address css={{ fontStyle: 'normal' }}>
                    Stichting MijnGilde
                    <br />
                    Postadres: Het Zool 49, 8939 BL Leeuwarden
                    <br />
                    Telefoon: 06-52401129
                    <br />
                    Mail:{' '}
                    <a href={obfuscator.href} onClick={obfuscator.handleClick}>
                      {obfuscator.email}
                    </a>
                    <br />
                    ANBI nummer: 861949237
                  </address>
                </main>
              </Section>
            </div>
          </Container>
        </Main>

        <Footer />
      </Layout>
    </>
  );
};

export default ContactPage;
